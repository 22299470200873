<template>
  <v-container  fluid tag="section">
    <v-row justify="center">
      <v-col cols="12">
        <v-card elevation="0">
          <v-card-title>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="pretestAssessment.program.name"
                  label="Program"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="pretestAssessment.school.name"
                  label="School"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  v-model="pretestAssessment.assessment_tool"
                  label="Assessment Tool"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field

                  label="Assessment Date"
                  v-model="pretestAssessment._date"
                  readonly
                ></v-text-field
              ></v-col>
            </v-row>
          </v-card-title>

          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="pretestLearners"
              :items-per-page="5"
              class="elevation-0"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Learners</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                </v-toolbar>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  color="primary"
                  @click="editItem(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon small color="error" @click="deleteItem(item)">
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-btn color="success" tile @click="approvePreAssessment" v-if="userlogin.approver">
              <v-icon>mdi-content-save</v-icon> Approve
            </v-btn>

            <v-spacer></v-spacer>
            <v-btn @click="$router.push('../assessmentlist')" tile>
              <v-icon>mdi-cancel</v-icon>Cancel</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { sync,dispatch } from "vuex-pathify";
export default {
  // name: "PRETESTAPPROVAL",
  computed: {
    ...sync("assessment", ["el", "pretestAssessment","pretestLearners",'approvePreTest']),
    ...sync("user", ["userlogin"]),
  },
  data: () => ({
    headers: [
      { text: "Last Name", value: "learner.surname" },
      { text: "First Name", value: "learner.gname" },
      { text: "Grade Level", value: "gradeLvl" },
      { text: "Type", value: "type" },
      { text: "Value", value: "_value" },
      { text: "Level", value: "_level" },
    ],
    preTestAssessmentForm: {
      program_id: "",
      school_id: "",
      assessment_tool: "",
      _date: "",
    },
    preTestLearnersList: [],
  }),

  onMounted(){
    this.loadAssessmentData()
  },

 
  methods: {
    async approvePreAssessment(){
      this.approvePreTest.assessment_id=this.pretestAssessment.id
      this.approvePreTest._status='PRETEST APPROVED'
      this.approvePreTest.approved=true
      this.approvePreTest.users_id=this.userlogin.id
      await dispatch('assessment/approvePretest')
      this.el=3
    },

    loadAssessmentData(){
      dispatch('assessment/loadAssessment_id')
    }
  },
};
</script>